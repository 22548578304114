<template>
  <v-dialog
    v-model="modal"
    max-width="1000"
    persistent>
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h5 white--text font-weight-bold">
              Seleção de campos
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          class="mt-1 pb-0"
          style="height: 320px;">
          <v-container
            fuild
            class="fill-height pb-0">
            <v-row>
              <v-col
                cols="12"
                class="text-center">
                <span
                  class="text-h6 wineLabel--text font-weight-regular">
                  Utilize o modelo padrão ou defina abaixo os campos que devem ser apresentados no relatório.
                </span>
              </v-col>
              <v-col
                cols="12">
                <div
                  class="d-flex justify-end">
                  <v-btn
                    outlined
                    small
                    class="mb-2"
                    :disabled="verifyIfEnabled || setPattern || selectedOptions.length === 0"
                    @click="setDefaultModel">
                    Redefinir modelo padrão
                  </v-btn>
                </div>
                <DualListBox
                  ref="DualListBox"
                  :availableOptions="availableOptions"
                  :selectedOptions="selectedOptions"
                  :verifyFields="verifyIfEnabled"
                  @selectedOptions="setSelectedOptions"
                  @availableOptions="setAvaliableOptions"
                  @verifyFields="emitVerifyFields"
                  @updateParttern="setPattern = false"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-13 pb-8 pt-7">
          <v-row>
            <v-spacer />
            <v-btn
              height="50"
              width="150"
              color="blue-grey lighten-5"
              class="rounded-pill mr-6"
              @click="close">
              <v-icon
                color="wine">
                mdi mdi-arrow-left
              </v-icon>
              <span
                class="wine--text text-h6">
                Voltar
              </span>
            </v-btn>
            <v-btn
              height="50"
              width="260"
              color="wine"
              class="rounded-pill"
              :disabled="selectedOptions.length <= 1"
              @click="confirm">
              <span
                class="white--text text-h6">
                Gerar
              </span>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
      <SnackbarCustomize ref="SnackbarCustomize" />
    </template>
  </v-dialog>
</template>
<script>
import OperationalService from '@/services-http/operational/OperationalService';
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import DualListBox from '@/components/DualListBox/DualListBox.vue';
export default {
  name: "GenerateReportModal",

  components: {
    DualListBox,
    SnackbarCustomize
  },

  data: () => ({
    operationalService: null,
    verifyIfEnabled: false,
    availableFields: null,
    availableOptions: [],
    selectedOptions: [],
    defaultModel: [],
    isDefault: false,
    setPattern: false,
    carrierId: null,
    fileId: null,
    modal: false,
    ids: [],
  }),

  methods: {
    async open(data) {
      this.modal = true;
      this.ids = data.ids;
      this.carrierId = data.carrierId;
      this.fileId = data.fileId;
      await this.getInvoiceReportFields();
      this.loadDefaultModel();
    },
    close() {
      this.availableOptions = [];
      this.selectedOptions = [];
      this.setPattern = false;
      this.isDefault = false;
      this.carrierId = null;
      this.fileId = null;
      this.modal = false;
      this.ids = [];
    },
    confirm() {
      this.$emit('confirm', {
        fields: this.selectedOptions,
        fileId: this.fileId,
        carrierId: this.carrierId,
        isDefault: this.isDefault,
      })
      this.close();
		},
    emitVerifyFields(event) {
      this.verifyIfEnabled = event;
    },
    async getInvoiceReportFields() {
      const params = new URLSearchParams();
      params.append('carrierId', parseInt(this.carrierId));

      await this.operationalService.GetInvoiceReportFields(params).then((res) => {
				this.availableOptions = Object.entries(
          res.data.availableFields
        ).map(([id, name]) => ({ id, name }));

        this.defaultModel = Object.entries(
          res.data.selectedFields
        ).map(([id, name]) => ({ id, name }));

        this.availableFields = this.availableOptions;
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os campos.');
      }).finally(() => {
      });
    },
    loadDefaultModel() {
      this.selectedOptions = this.defaultModel;
      this.availableOptions = this.availableFields.filter(
        el => this.defaultModel.some(
          item => el.id !== item.id
        )
      );

      this.availableOptions.sort((a, b) => (a.name > b.name ? 1 : -1));

      this.verifyIfEnabled = true;
    },
    setAvaliableOptions(event) {
      this.availableOptions = event;
      this.setVerifyFields();
    },
    setDefaultModel() {
      this.setPattern = true;
      this.isDefault = true;
    },
    setSelectedOptions(event) {
      this.selectedOptions = event;
      this.setVerifyFields();
    },
    setVerifyFields() {
      this.verifyIfEnabled = this.areListsEqual;
      this.isDefault = false;
    },
  },

  computed: {
    areListsEqual() {
      if (this.defaultModel.length !== this.selectedOptions.length) {
        return false;
      }

      return this.defaultModel.every((defaultEl, index) => {
        const selectEl = this.selectedOptions[index];
        return JSON.stringify(defaultEl) === JSON.stringify(selectEl);
      });
    },
  },

  created() {
    this.operationalService = new OperationalService();
  },
}
</script>